<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name:"App",
  created(){
    
  }
}
</script>

<style lang="less">

html{
	margin: 0;
	padding: 0;
}
body,p,div,ul,ol{
	margin: 0;
	padding: 0px;
  box-sizing: border-box;
}
ul,ol{
  list-style: none;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
}
a{
  text-decoration: none;
}
</style>
