<template>
  <div class="home">
		<div :class="scrollTop == 0?'nav':'nav nav-active'">
			<div class="inner">
				<div class="logo-box" v-if="false">
					<img src="../../assets/teacher/logo.png" alt="" class="logo">
					<span></span>
				</div>				
				<div class="list-box">
          <router-link to="/mobile/home" class="item nav-item1">首页</router-link>
          <router-link to="/mobile/class" class="item active">课程中心</router-link>
          <router-link to="/mobile/teacher" class="item nav-item1">名师介绍</router-link>
					<router-link to="/mobile/aboutus" class="item nav-item1">关于我们</router-link>
				</div>
			</div>
		</div>
		<div class="header-banner">
			<el-carousel arrow="never">
				<el-carousel-item>
					<img src="../../assets/teacher/1-1.jpg" alt="" style="width:100%">
				</el-carousel-item>
				<el-carousel-item>
					<img src="../../assets/teacher/1-2.jpg" alt="" style="width:100%">
				</el-carousel-item>
				<el-carousel-item>
					<img src="../../assets/teacher/1-3.jpg" alt="" style="width:100%">
				</el-carousel-item>
			</el-carousel>
		</div>
		<div class="container">
			<div class="count-box2">
				<div class="box-title">
					<div class="title-zh">
						<span class="num">01</span>
						<span class="text">数学专题课</span>
					</div>
					<div class="title-english">Math Topical Course</div>
				</div>
				<div class="class-box">
					<div class="class-text">课程主要是以新教材高中数学，对于基本知识与基本方法进行讲解，目的在于让学生回归基础，并在回归基础的过程中超越基础。课程中对于很多稍难的问题进行详细的剖析，抛开套路与模板，让学生真正的达到一通百通。</div>
					<img src="../../assets/class/1.jpg" alt="" class="class-img">
				</div>
			</div>
		</div>
		<div class="container">
			<div class="count-box2">
				<div class="box-title">
					<div class="title-zh">
						<span class="num">02</span>
						<span class="text">语文技巧课</span>
					</div>
					<div class="title-english">Chinese Finesse Course</div>
				</div>
				<div class="class-box">
					<div class="class-text">《高中语文技巧课程》是根据高考最新题型和高考命题规律研发出的一套面向全体高中生的语文宝藏课程。无论你是高一高二还是高三；无论是是80分90分还是120分，只要你能认真收看就能得到相应的提高。我的的课程里有针对高考题型的知识讲解，有高考真题的定向讲解，有老师独创的解题方法，语文提分就是这么简单。</div>
					<img src="../../assets/class/2.jpg" alt="" class="class-img">
				</div>
			</div>
		</div>
		<div class="container">
			<div class="count-box2">
				<div class="box-title">
					<div class="title-zh">
						<span class="num">03</span>
						<span class="text">英语题型课</span>
					</div>
					<div class="title-english">English Topical Course</div>
				</div>
				<div class="class-box" style="margin-bottom:0">
					<div class="class-text">本课程以高考考察题型为中心，遵循考试出题规律，针对高中英语必考题型，讲解解题技巧，及超重点语法和题型中所需高频词的总结和记忆方法。</div>
					<img src="../../assets/class/3.jpg" alt="" class="class-img">
				</div>
			</div>
			<div class="out-box">
				<div class="title">授课内容</div>
				<div class="info-box">
					<img src="../../assets/class/star.png" alt="" class="info-star">
					<div class="info-class">
						<div class="class-item">
							<img src="../../assets/class/icon-class.png" alt="">
							<div class="class-text">
								题型一:语法填空
							</div>
						</div>
						<div class="class-item">
							<img src="../../assets/class/icon-class.png" alt="">
							<div class="class-text">
								题型二:阅读理解
							</div>
						</div>
						<div class="class-item" >
							<img src="../../assets/class/icon-class.png" alt="">
							<div class="class-text">
								题型三:应用文写作
							</div>
						</div>
						<div class="class-item">
							<img src="../../assets/class/icon-class.png" alt="">
							<div class="class-text">
								题型四:读后续写
							</div>
						</div>
						<div class="class-item" style="margin-bottom:0">
							<img src="../../assets/class/icon-class.png" alt="">
							<div class="class-text">
								题型五:完形填空
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="out-box">
				<div class="title">授课亮点</div>
				<div class="course-list">
					<div class="list-item">
						<div class="item-head">
							<div class="item-number">01</div>
							<div class="head-info">
								<div class="info-title">高考题型讲解，快速提分</div>
							</div>
						</div>
						<div class="item-content">与学校的常规授课进行互补，本课程通过考试题型进行讲解。从如何考出发，引起学生兴趣，再对一行重点语法和词汇进行补充，让学生提起英语学科的兴趣</div>
						<div class="item-left"></div>
					</div>
					<div class="list-item">
						<div class="item-head">
							<div class="item-number">02</div>
							<div class="head-info">
								<div class="info-title">历年真题演练，弯道超车</div>
							</div>
						</div>
						<div class="item-content">本课程精选历年高考题和经典模拟题，构建考试母题模型。真正做到教考紧密结合</div>
						<div class="item-left"></div>
					</div>
				</div>
			</div>
		</div>
		<div class="container">
			<div class="count-box2">
				<div class="box-title">
					<div class="title-zh">
						<span class="num">04</span>
						<span class="text">物理技巧课</span>
					</div>
					<div class="title-english">Physics Finesse Course</div>
				</div>
				<div class="class-box">
					<div class="class-text">本套物理技巧课以高考题和经典模拟题为载体，讲解高考物理秒杀技巧方法，可以帮助同学们在考场上节省时间，甚至可以预判题目答案！解题方法全面覆盖并不仅限于世面常见的技巧，更多的是老师在教学中自主研发独创的解题技巧。因为是针对高考高频考点设置归纳，对于各地考生学习均有提升效果，尤其是高三冲刺的同学，学透本套课程可以短时间极大程度提高物理成绩。</div>
					<img src="../../assets/class/4.jpg" alt="" class="class-img">
				</div>
			</div>
		</div>
		<div class="container">
			<div class="count-box2">
				<div class="box-title">
					<div class="title-zh">
						<span class="num">05</span>
						<span class="text">物理实验课</span>
					</div>
					<div class="title-english">Physics Laboratory Course</div>
				</div>
				<div class="class-box">
					<div class="class-text">物理实验在高考中属于必考题型，但是对于大多数同学来说，实验又是软肋。所以本套物理实验课程，内容上选取最新高考真题和经典模拟题，帮助同学们掌握实验原理和创新拓展考法。课程总体设置21个实验，均为近年高考常考类型，通过本套课程可以帮助同学们拿到高考实验题型分数（13-15分）。适合高一高二同步学习，或者高二下学期以及高三同学冲刺学习！</div>
					<img src="../../assets/class/5.jpg" alt="" class="class-img">
				</div>
			</div>
		</div>
		<div class="container">
			<div class="count-box2">
				<div class="box-title">
					<div class="title-zh">
						<span class="num">06</span>
						<span class="text">化学考点直击课</span>
					</div>
					<div class="title-english">Chemistry Hitting Course</div>
				</div>
				<div class="class-box">
					<div class="class-text">本课程以近三年高考真题为切入点，从“知识，习题，技巧”三个维度深度剖析，达到对高考化学考点“了解，理解，应用”三个境界。以刘文壮老师的多年高考教研教学经验为基础，配合众多原创高考化学快速解题技巧和秒杀大招，达到快速提分的效果。课程风趣幽默，技巧简单易懂。本套课程可使低分学员平均提分20有余。</div>
					<img src="../../assets/class/6.jpg" alt="" class="class-img">
				</div>
			</div>
		</div>
		<div class="container">
			<div class="count-box2">
				<div class="box-title">
					<div class="title-zh">
						<span class="num">07</span>
						<span class="text">生物题型课</span>
					</div>
					<div class="title-english">Biology Topical Course</div>
				</div>
				<div class="class-box" style="margin-bottom:0">
					<div class="class-text">本课程结合考纲，遵循考试出题规律，针对高中生物必考大题题进行高效讲解。通过模型构建及解题技巧讲解，带你完成一次成绩的快速提升。</div>
					<img src="../../assets/class/7.jpg" alt="" class="class-img">
				</div>
			</div>
			<div class="out-box">
				<div class="title">授课内容</div>
				<div class="info-box" >
					<img src="../../assets/class/star.png" alt="" class="info-star">
					<div class="info-class info-class2">
						<div class="class-item">
							<img src="../../assets/class/icon-class.png" alt="">
							<div class="class-text">
								代谢专题——ATP与酶
							</div>
						</div>
						<div class="class-item">
							<img src="../../assets/class/icon-class.png" alt="">
							<div class="class-text">
								代谢专题——细胞呼吸
							</div>
						</div>
						<div class="class-item">
							<img src="../../assets/class/icon-class.png" alt="">
							<div class="class-text">
								代谢专题——光合作用
							</div>
						</div>
						<div class="class-item">
							<img src="../../assets/class/icon-class.png" alt="">
							<div class="class-text">
								遗传专题——基因分离定律与自由组合定律
							</div>
						</div>
						<div class="class-item">
							<img src="../../assets/class/icon-class.png" alt="">
							<div class="class-text">
								遗传专题——伴性遗传
							</div>
						</div>
						<div class="class-item">
							<img src="../../assets/class/icon-class.png" alt="">
							<div class="class-text">
								人体生命活动调节专题
							</div>
						</div>
						<div class="class-item" style="margin-bottom:0">
							<img src="../../assets/class/icon-class.png" alt="">
							<div class="class-text">
								种群群落及生态系统专题
							</div>
						</div>
						<div class="class-item" style="margin-bottom:0">
							<img src="../../assets/class/icon-class.png" alt="">
							<div class="class-text">
								生物技术与工程专题
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="out-box">
				<div class="title">授课亮点</div>
				<div class="course-list">
					<div class="list-item">
						<div class="item-head">
							<div class="item-number">01</div>
							<div class="head-info">
								<div class="info-title">专题讲解，掌握方法</div>
							</div>
						</div>
						<div class="item-content">不同于普通学校的常规授课，本课程通过考试题型分专题讲解。从基础考点到拔高技巧，再到专项题型突破，力求学员在考试中“稳拿分”。</div>
						<div class="item-left"></div>
					</div>
					<div class="list-item">
						<div class="item-head">
							<div class="item-number">02</div>
							<div class="head-info">
								<div class="info-title">多向练习，螺旋提升</div>
							</div>
						</div>
						<div class="item-content">课程选题不同于市面一般习题册，题量看似多而精题少，浪费学生时间。本课程精选历年高考题和经典模拟题，构建考试母题模型。使题型讲解与技巧不分家，锻炼学员解题思维，从而稳步提升成绩。</div>
						<div class="item-left"></div>
					</div>
					<div class="list-item">
						<div class="item-head">
							<div class="item-number">03</div>
							<div class="head-info">
								<div class="info-title">视频详解，讲透思路</div>
							</div>
						</div>
						<div class="item-content">题型讲解由浅入深，夯实基础且重视技巧，讲解解题思路详细而透彻。</div>
						<div class="item-left"></div>
					</div>
				</div>
			</div>
		</div>
		<div class="container">
			<div class="count-box2">
				<div class="box-title">
					<div class="title-zh">
						<span class="num">08</span>
						<span class="text">政治技巧课</span>
					</div>
					<div class="title-english">Politics Skills Course</div>
				</div>
				<div class="class-box">
					<div class="class-text">本套课程按知识模块划分，每个知识模块可分为客观题（选择题）与主观题（大题）两大部分。客观题（选择题）部分按知识点进行讲解并配有解题技巧，融合历年高考真题进行练习讲解，在每个知识模块开始之处会进行五年高考真题分析，对接高考，实战性强。主观题（大题）部分每个不同知识模块均配有相应答题模板，以及本块主观题（大题）的特点分析，并配有历年高考真题进行剖析讲解。</div>
					<img src="../../assets/class/8.jpg" alt="" class="class-img">
				</div>
			</div>
		</div>
		<div class="container">
			<div class="count-box2">
				<div class="box-title">
					<div class="title-zh">
						<span class="num">09</span>
						<span class="text">历史技巧课</span>
					</div>
					<div class="title-english">History Skills Course</div>
				</div>
				<div class="class-box">
					<div class="class-text">本套课程按题型模块划分，将模块可分为选择题与主观题两大部分。选择题部分按问题类型进行讲解答题技巧，融合历年高考真题进行练习讲解，并融合一定的必要知识点，力图对接高考，增强实战性强。主观题部分分为材料分析和主观探讨两种主要题型，材料分析配有相应答题模板，主观探讨题型总结了相应的答题规律、技巧，两种题型配有历年高考真题进行剖析讲解。</div>
					<img src="../../assets/class/9.jpg" alt="" class="class-img">
				</div>
			</div>
		</div>
		<div class="container">
			<div class="count-box2">
				<div class="box-title">
					<div class="title-zh">
						<span class="num">10</span>
						<span class="text">历史特训课</span>
					</div>
					<div class="title-english">History Training Course</div>
				</div>
				<div class="class-box">
					<div class="class-text">本套课程模块可分为突破选择题与攻克历史核心素养两大部分。选择题部分按材料所呈现的五种关键词类型进行讲解，并总结相应的答题技巧，在此基础上融合历年高考真题进行练习讲解，力图做到冲刺、突破，在较快的时间内做到成绩的明显提高，增强临考的实用性。攻克历史核心素养包含选择题和主观题两部分，进行理论总结、规律概括和技巧训练，力图掌握五种核心素养的题目、答案的呈现形式，以配有历年高考真题进行剖析讲解。</div>
					<img src="../../assets/class/9.jpg" alt="" class="class-img">
				</div>
			</div>
		</div>
		<div class="container">
			<div class="count-box2">
				<div class="box-title">
					<div class="title-zh">
						<span class="num">11</span>
						<span class="text">地理技巧课</span>
					</div>
					<div class="title-english">Geography Skills Course</div>
				</div>
				<div class="class-box" style="526px">
					<div class="class-text">本课程按照高考最新命题趋势，精选高考高频考点、题型，争取在最短时间内，让同学掌握地理命题趋势、解题思路方法，取得优异成绩。课程按照自然地理（地球运动、大气、水、地表形态、自然地理环境）和人文地理（人口城市交通、产业活动）两大体系，先介绍重要知识点、常考题型、解题思路方法，然后以近几年尤其是2022年高考真题为例，深入浅出的讲解高考高频考点、题型的破解之法。高考地理分为选择题和综合题，本课程同时兼顾，特别是对高频考点的综合题做了专题讲解，帮助同学构建解题理念，带领大家走出综合题没思路、不会写的误区。本课程每节还专门配套了课后练习，以供各位同学在听课之余巩固提升、融会贯通。
</div>
					<img src="../../assets/class/10.jpg" alt="" class="class-img">
				</div>
			</div>
		</div>
		<div class="container">
			<div class="component-footer">
				<div class="component-footer-common">
					<div>用户隐私政策</div>
					<div class="line"></div>
					<div>用户服务协议</div>
					<div class="line"></div>
					<div>吉ICP备2022005057号-2</div>
					<div class="line"></div>
					<div>服务热线:400-8767985</div>
				</div>
				<!-- <div>Copyright @ 2009-2022 菲尔莱（北京）科技有限公司 All Rights Reserved 京ICP备18056030号-24</div>
				<div>公司地址：北京市朝阳区来广营容和路1号 叶青大厦北园6层</div> -->
			</div>
		</div>
  </div>
</template>

<script>
export default {
  name: 'Class',
  components: {
    
  },
  data(){
	  return {
			scrollTop:0,
	  }
  },
  mounted() {
	const _this = this;
		window.onscroll = function(){
			const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
			// console.log("scrollTop=========",scrollTop)
			_this.scrollTop = scrollTop
		}
	}
}
</script>

<style lang="less" scoped="scoped">
.nav{
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	z-index: 99;
	height: 210px;
	transition: all .3s;
	padding: 0 30px;
	font-size: 28px;
	box-sizing: border-box;
	.inner{
		width: 100%;
		height: 100%;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.logo-box{
			display: flex;
			align-items: center;
		}
		.logo{
			display: block;
			width: 40px;
			height: 40px;
			cursor: pointer;
			margin-right: 10px;
		}
		.list-box{
			display: flex;
			.item{
				font-size: 48px;
				line-height: 96px;
				margin-left: 37px;
				cursor: pointer;
				padding: 0 69px;
			}
			.active{
				font-weight: 700;
				border-radius: 48px;
				color: #ff1001;
				background-color: #ffefeb;
			}
			.nav-item1{
				color: #3b3a43;
			}
			.nav-item2{
				color: #ffffff;
			}
		}
	}
}
.nav-active{
	background: #fff;
	box-shadow: 0 3px 3px #eee;
}
/deep/ .el-carousel__container{
	height: 780px;
}
/deep/ .header-banner .el-carousel__indicators--horizontal{
	bottom: 78px !important;
	left: 100px !important;
	transform: none !important;
}
/deep/ .header-banner .el-carousel__button{
	width: 48px !important;
	height: 6px !important;
	border-radius: 4px !important;
	background: rgba(59,58,67,.2) !important;
	opacity: 1 !important;
}
/deep/ .is-active .el-carousel__button{
	background: #ffffff !important;
}
.container{
	position: relative;
	z-index: 2;
	.count-box{
		position: relative;
		width: 1180px;
		margin: 0 auto;
		padding-top: 140px;
		background-color: #fff;
	}
	.count-box2{
		padding-top: 80px;
		background-color: #fff;
	}
}
.box-title{
	width: 100%;
  text-align: center;
  line-height: 1;
  font-weight: 600;
	.title-zh{
		font-size: 102px;
		color: #3b3a43;
		.num{
			position: relative;
			font-size: 210px;
			top: 4px;
			margin-right: 20px;
		}
		.text{
			position: relative;
			top: -4px;
		}
	}
	.title-english{
		color: #f0f0f0;
		font-size: 96px;
		margin-top: 6px;
	}
}
.component-footer{
	width: 100%;
	height: 240px;
	background: #4f5669;
	font-size: 40px;
	color: #fffdfd;
	text-align: center;
	flex-direction: column;
	justify-content: center;
	display: flex;
	align-items: center;
	.component-footer-common{
		display: flex;
		align-items: center;
		// justify-content: center;
		.line{
			width: 1px;
			height: 70px;
			margin: 0 48px;
			background: #fffdfd;
		}
	}
}

.class-box{
	position: relative;
	z-index: 3;
	width: 70%;
	box-sizing: border-box;
	padding: 65px 0 70px 75px;
	margin: 300px auto;
	.class-text{
		padding: 100px 69px 100px 360px;
		box-sizing: border-box;
		font-family: PingFangSC-Regular;
		font-size: 70px;
		color: #3b3a43;
		letter-spacing: 0;
		text-align: justify;
		box-shadow: 0 4px 30px 0 #f3f3f3;
		border-radius: 18px;
		line-height: 1.5;
	}
	.class-img{
		width: 424px;
		height: 424px;
		display: block;
		border-radius: 18px;
		position: absolute;
		left: -100px;
		top: -40px;
	}
}
.out-box{
	width: 90%;
	margin: 0 auto;		
	.title{
		font-size: 108px;
		color: #3b3a43;
		letter-spacing: 0;
		text-align: justify;
		line-height: 1;
		text-align: left;
		// text-indent: 47px;
		font-weight: 600;
	}
	.info-box{
		width: 100%;
		margin: 200px auto;
		height: 560px;
		flex: 1 1;
		border-radius: 24px;
		background: #fff6f4;
		display: flex;
		align-items: center;
		flex-direction: row-reverse;
		position: relative;
		.info-star{
			width: 382px;
			height: 290px;
			position: absolute;
			left: 60px;
			bottom: 0;
		}
		.info-class{
			width: 70%;
			display: flex;
			flex-wrap: wrap;
			position: relative;
			.class-item{
				display: flex;
				align-items: center;
				width: 50%;
				margin-bottom: 40px;
				img{
					width: 92px;
					height: 92px;
					display: flex;
				}
				.class-text{
					font-size: 48px;
					color: #3b3a43;
					letter-spacing: 1px;
					margin: 0 20px;
				}
			}
		}
		.info-class2{
			.class-text{
				font-size: 35px !important;
			}
		}
	}
	.course-list{
		margin-top: 200px;
		display: flex;
		flex-wrap: wrap;
		.list-item:nth-child(2n){
			// margin-left: 94px;
		}
		.list-item{
			cursor: pointer;
			transition: all .2s ease-in-out;
			position: relative;
			width: 100%;
			height: 800px;
			background: #fff;
			box-shadow: 0 2px 40px 0 rgba(133,138,150,.12);
			border-radius: 0 24px 24px 0;
			box-sizing: border-box;
			margin-bottom: 150px;
			margin-left: 54px;
			.item-head{
				height: 240px;
				display: flex;
				align-items: center;
				background: #f7f8fa;
				position: relative;
				overflow: hidden;
				.item-number{
					opacity: .21;
					font-family: Impact;
					font-size: 140px;
					color: #cfcfcf;
					letter-spacing: 0;
					line-height: 140px;
					font-weight: 800;
					position: absolute;
					right: 33px;
					bottom: -10px;
				}
				.head-info{
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-content: center;
					.info-title{
						font-family: PingFangSC-Semibold;
						font-size: 80px;
						color: #ff4935;
						letter-spacing: 0;
						text-align: justify;
						font-weight: 800;
						padding-left: 120px;
						position: relative;
					}
					.info-title::before{
						content: "";
						width: 30px;
						height: 30px;
						display: block;
						border-radius: 50%;
						position: absolute;
						top: 40px;
						left: 70px;
						background: #ff4935;
					}
				}
			}
			.item-content{
				font-size: 70px;
				color: #3b3a43;
				letter-spacing: 0;
				text-align: justify;
				margin-top: 32px;
				padding: 0 88px;
			}
			.item-left{
				position: absolute;
				bottom: 0;
				left: -8px;
				width: 16px;
				height: 100%;
				background: -webkit-linear-gradient(top,#ffa77d,#ff2a1d);
				border-radius: 24px 0 0 24px;
			}
		}
	}
}
</style>