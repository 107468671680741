<template>
  <div class="home">
		<div :class="scrollTop == 0?'nav':'nav nav-active'">
			<div class="inner">
				<div class="logo-box" v-if="false">
					<img src="../assets/teacher/logo.png" alt="" class="logo">
					<span></span>
				</div>				
				<div class="list-box">
          <router-link to="/" class="item active">首页</router-link>
					<router-link to="/class" class="item nav-item1">课程中心</router-link>
          <router-link to="/teacher" class="item nav-item1">名师介绍</router-link>
					<router-link to="/aboutus" class="item nav-item1">关于我们</router-link>
				</div>
			</div>
		</div>
		<div class="header-banner">
			<el-carousel height="640px" arrow="never">
				<el-carousel-item>
					<img src="../assets/teacher/1-1.jpg" alt="" style="width:100%">
				</el-carousel-item>
				<el-carousel-item>
					<img src="../assets/teacher/1-2.jpg" alt="" style="width:100%">
				</el-carousel-item>
				<el-carousel-item>
					<img src="../assets/teacher/1-3.jpg" alt="" style="width:100%">
				</el-carousel-item>
			</el-carousel>
		</div>
		<div class="container container1">
			<div class="count-box count-box1">
				<div class="box-title">
					<div class="title-zh">
						<span class="num">01</span>
						<span class="text">精选课程</span>
					</div>
					<div class="title-english">Selected courses</div>
				</div>
				<div class="box1-list">
					<div class="list-item">
						<img src="../assets/teacher/1-1.png" alt="" class="item-img">
						<div class="item-title">基础课</div>
						<div class="item-des">同步课本章节总结出来的，帮助同学们培优补差，起到知识的衔接与预习的作用</div>
						<!-- <div class="item-btn">
							了解详情
							<img src="../assets/home/icon-arrow.png" alt="">
						</div> -->
					</div>
					<div class="list-item">
						<img src="../assets/teacher/1-2.png" alt="" class="item-img">
						<div class="item-title">专题课</div>
						<div class="item-des">为知识点不会不牢固的同学们精心设计的产品，涵盖高考常考必考知识点、题型归纳概括总结。让每一位同学都能轻松、简单、高效的夯实中等难度题型，打造完整的学科体系。</div>
						<!-- <div class="item-btn">
							了解详情
							<img src="../assets/home/icon-arrow.png" alt="">
						</div> -->
					</div>
					<div class="list-item">
						<img src="../assets/teacher/1-3.png" alt="" class="item-img">
						<div class="item-title">题型课</div>
						<div class="item-des">根据高考总结出来的常考必考知识点课程，帮助同学们在做题的过程中掌握一道题会做一类题，培养出同学们在做题中举一反三，灵活运用的课程。</div>
						<!-- <div class="item-btn">
							了解详情
							<img src="../assets/home/icon-arrow.png" alt="">
						</div> -->
					</div>
					<div class="list-item">
						<img src="../assets/teacher/1-4.png" alt="" class="item-img">
						<div class="item-title">技巧课</div>
						<div class="item-des">对整个高中的学习方法、答题技巧和应试技巧的总结的课程，最实用、高效便捷的高分模板技巧及秒杀大招，极大提高答题效率与时间。</div>
						<!-- <div class="item-btn">
							了解详情
							<img src="../assets/home/icon-arrow.png" alt="">
						</div> -->
					</div>
				</div>
			</div>
			<div class="count-box2">
				<div class="box-title">
					<div class="title-zh">
						<span class="num">02</span>
						<span class="text">平台优势</span>
					</div>
					<div class="title-english">Advantage</div>
				</div>
				<div class="advantage-list">
					<div class="advantage-item">
						<div class="item-left">
							<img src="../assets/teacher/2-1.png" alt="">
						</div>
						<div class="item-right">
							<div class="item-title">
								<img src="../assets/home/icon-file.png" alt="">完善的课程管理平台
							</div>
							<div class="item-des">小白式操作、无需下载安装、PC端+公众号学习用户界面简洁清晰</div>
						</div>
					</div>
					<div class="advantage-item">
						<div class="item-right">
							<div class="item-title">
								<img src="../assets/home/icon-video.png" alt="">多元的课程样式
							</div>
							<div class="item-des">文档视频多种形式、不定期分享学习资料、考前密押卷、心理辅导，全程助力授课过程。</div>
						</div>
						<div class="item-left">
							<img src="../assets/teacher/2-2.png" alt="">
						</div>						
					</div>
					<div class="advantage-item">
						<div class="item-left">
							<img src="../assets/teacher/2-3.png" alt="">
						</div>
						<div class="item-right">
							<div class="item-title">
								<img src="../assets/home/icon-user.png" alt="">三师模式
							</div>
							<div class="item-des">定期更新视频课程，学情分析师+金牌售后辅导+学霸组解题持续跟踪到高考。</div>
						</div>
					</div>
					<div class="advantage-item">
						<div class="item-right">
							<div class="item-title">
								<img src="../assets/home/icon-video.png" alt="">丰富的课程内容
							</div>
							<div class="item-des">高中基础全科知识点体系、高考冲刺课程、海量题库、高效学习方法，帮助快读提分。</div>
						</div>
						<div class="item-left">
							<img src="../assets/teacher/2-4.png" alt="">
						</div>						
					</div>
				</div>
			</div>
		</div>
		<div class="container">
			<div class="count-box">
				<div class="box-title">
					<div class="title-zh">
						<span class="num">03</span>
						<span class="text">名师团队</span>
					</div>
					<div class="title-english">Teacher resources</div>
				</div>
				<div class="adviser-container" v-if="false">
					<div class="adviser-title">• 荣誉顾问 •</div>
					<div class="adviser-content">
						<img class="adviser-teacher" src="../assets/home/7.png"/>
						<img class="adviser-label" src="../assets/home/8.png"/>
						<div class="adviser-info">
							<p>经济学家，新金融专家，著名财经金融评论家，港股100强研究中心专家</p>
							<p>中概股研究中心主持人，央广财经特约评论员，中国产业经济调研中心特邀研究员，中国金融智库特邀研究员</p>
							<p>BWCHINESE中文网和和讯网特约专栏作家，2017中国财经领域具影响力创作者(今日头条)</p>
						</div>
						<div class="adviser-feature">在中国财经金融界享有盛誉，具有极高知名度、号召力和影响力。前大型金融企业高管，注册高级咨询师。</div>
						<div class="adviser-work">
							<p>
								新著作<span>《新经济论》</span>。从2012年开始历时7年多时间，先后完成了<span>《互联网金融革命》</span>、<span>《金融科技》</span>、<span>《AI金融时代》</span>三部著作，构成新金融三部曲。
							</p>
						</div>
					</div>
				</div>
				<div class="adviser-title">• 实战名师 •</div>
				<el-carousel height="470px" class="teacher-container" arrow="always" indicator-position="outside">
					<el-carousel-item>
						<div class="carousel-box">
							<img src="../assets/ms/14.jpg" alt="" class="carousel-item"></img>
							<div class="carousel-item-info">
								<div class="teacher-name">王钟玉</div>
								<ul class="teacher-desc">
									<li>毕业于重点211大学</li>
									<li>高考数学满分</li>
									<li>精准把握考点，连续三年高考命中率达80%</li>
									<li>授课时长数十万，服务人次过万</li>
									<li>注重引导学生消除思维定势，学会寻找规律，灵活运用数学知识来解答问题</li>
									<li>教学过程中幽默风趣，善于激发学生的兴趣，让学生更积极地学数学</li>
									<li>学生平均提升分数30+，最高可达50+</li>
									<li>注重教学方法，以独特的教育手法使学生考入985,211院校</li>
								</ul>
							</div>
						</div>
					</el-carousel-item>
					<el-carousel-item>
						<div class="carousel-box">
							<img src="../assets/ms/13.jpg" alt="" class="carousel-item"></img>
							<div class="carousel-item-info">
								<div class="teacher-name">朱楠</div>
								<ul class="teacher-desc">
									<li>多年从事一线地理教学</li>
									<li>中学一级教师</li>
									<li>省直机关优秀班级</li>
									<li>善于分析、比较和归纳地理事实,培养学生的地理思维和解决问题的能力</li>
									<li>口齿清晰，语言能力表达强，思维活跃，能充分调动学生学习的主动性</li>
									<li>《北京师范大学学报》、《第四纪研究》、《中学地理教学参考》、《考试周刊》、《试题与研究》等期刊发表文章多篇，参与编写《地质学基础》</li>
								</ul>
							</div>
						</div>
					</el-carousel-item>
					<el-carousel-item>
						<div class="carousel-box">
							<img src="../assets/ms/12.jpg" alt="" class="carousel-item"></img>
							<div class="carousel-item-info">
								<div class="teacher-name">刘健彬</div>
								<ul class="teacher-desc">
									<li>毕业于中国医科大学生物科学与生物技术</li>
									<li>新课标全国卷高考命题研究员</li>
									<li>三省六市联考命题组成员,理科硕士学位</li>
									<li>高考阅卷组核心成员</li>
									<li>独创《生物学基础必考知识巧记法》,使生物课变得多彩有趣，同时深刻记住所学知识不易忘记。</li>
									<li>注重提高学生的生物科学素养，激发学生的思考潜能，拓展学生的创造能力。</li>
									<li>授课紧扣生物学科特点，擅于从学生熟悉的生活环境切入新课程的教学</li>
								</ul>
							</div>
						</div>
					</el-carousel-item>
					<el-carousel-item>
						<div class="carousel-box">
							<img src="../assets/ms/1.jpg" alt="" class="carousel-item"></img>
							<div class="carousel-item-info">
								<div class="teacher-name">池昀峰</div>
								<ul class="teacher-desc">
									<li>数学组讲师：清华大学</li>
									<li>2021年高考692，数学150</li>
									<li>高二时参加中国科学技术大学考试，进入全国前400</li>
									<li>擅于与学生互动，帮助学生构建知识体系和能力体系，让学生有好的数学思维。</li>
									<li>注重引导学生对所学知识进行归纳总结，揭示内在规律，教给学生归纳整理的方法。</li>
									<li>熟知高考考点，在校期间担任讲师,近两年教学中共教出87名高考140分以上者。</li>
								</ul>
							</div>
						</div>
					</el-carousel-item>
					<el-carousel-item>
						<div class="carousel-box">
							<img src="../assets/ms/10.jpg" alt="" class="carousel-item"></img>
							<div class="carousel-item-info">
								<div class="teacher-name">魏宇航</div>
								<ul class="teacher-desc">
									<li>数学组讲师：中国南开大学</li>
									<li>高考数学满分</li>
									<li>中国奥林匹克竞赛高级教练员,全国高考数学应试专家</li>
									<li>参与并主持教育部“十三五”课题《区域联考命题评价标准设立》</li>
									<li>《2016年新课标高考数学试卷评析》荣获国家级论文一等奖</li>
									<li>10余年教学中教出高考数学140分以上者187名，其中包括高考满分8名</li>
								</ul>
							</div>
						</div>
					</el-carousel-item>
					<el-carousel-item>
						<div class="carousel-box">
							<img src="../assets/ms/7.jpg" alt="" class="carousel-item"></img>
							<div class="carousel-item-info">
								<div class="teacher-name">路双喜</div>
								<ul class="teacher-desc">
									<li>物理组讲师：《赢在微点》撰稿人</li>
									<li>高考阅卷组成员</li>
									<li>全国高考名师联盟物理学科带头人</li>
									<li>全国网络云课堂首席主讲</li>
									<li>《极简物理》系列丛书主编</li>
									<li>《高考物理模型分析技巧》课程创始人</li>
									<li>《十年高考》编委</li>
								</ul>
							</div>
						</div>
					</el-carousel-item>
					<el-carousel-item>
						<div class="carousel-box">
							<img src="../assets/ms/6.jpg" alt="" class="carousel-item"></img>
							<div class="carousel-item-info">
								<div class="teacher-name">刘文壮</div>
								<ul class="teacher-desc">
									<li>化学组讲师：上市公司天鸿十年高考系列丛书特聘教研员</li>
									<li>曾与诺贝尔奖得主生物化学家蒂姆.亨特进行深度学习交流</li>
									<li>独创三维三相体系，专精于从分数垫底到T1梯队的快速养成</li>
								</ul>
							</div>
						</div>
					</el-carousel-item>
					<el-carousel-item>
						<div class="carousel-box">
							<img src="../assets/ms/5.jpg" alt="" class="carousel-item"></img>
							<div class="carousel-item-info">
								<div class="teacher-name">竭超</div>
								<ul class="teacher-desc">
									<li>语文组讲师：古典文化博士中教一级</li>
									<li>中国考试研究院研究员</li>
									<li>基础教育研究中心副主任</li>
									<li>搜狐视频特约主讲人</li>
									<li>参与新课程教学参考编制和全国教学示范课等国家级教研项目</li>
									<li>《十年高考》语文学科总主编</li>
									<li>《题帮》《教材完全解读》《尖子生学案》教辅编写</li>
									<li>首创“五维定向导学法</li>
								</ul>
							</div>
						</div>
					</el-carousel-item>
					<el-carousel-item>
						<div class="carousel-box">
							<img src="../assets/ms/11.jpg" alt="" class="carousel-item"></img>
							<div class="carousel-item-info">
								<div class="teacher-name">吴桐</div>
								<ul class="teacher-desc">
									<li>政治组讲师：高中一线执教政治教师</li>
									<li>深挖考纲，从总纲要求、考核目标的变化结合试卷分析新高考命题方向，2020-2022押卷命中率80%</li>
									<li>多年从事高考文化课考前集训</li>
									<li>具备全日制高中政治教师资格证书</li>
								</ul>
							</div>
						</div>
					</el-carousel-item>
					<el-carousel-item>
						<div class="carousel-box">
							<img src="../assets/ms/4.jpg" alt="" class="carousel-item"></img>
							<div class="carousel-item-info">
								<div class="teacher-name">黄钟讯</div>
								<ul class="teacher-desc">
									<li>历史组讲师：历史学硕士</li>
									<li>中共中央宣传部主管“学习强国”平台主讲教师，央视频讲座教师</li>
									<li>《新华社发展研究》《高等学校文科学术论文集》2010年第4期全文转载</li>
									<li>《高中历史教学发展》《高中历史解题决策》主编</li>
									<li>《高考改革历史精编》编者 </li>
									<li>《分析高考历史》编委  </li>
								</ul>
							</div>
						</div>
					</el-carousel-item>
				</el-carousel>
			</div>
			<div class="count-box" v-if="false">
				<div class="box-title">
					<div class="title-zh">
						<span class="num">04</span>
						<span class="text">课研团队</span>
					</div>
					<div class="title-english">Research team</div>
				</div>
			</div>
			<!-- <div class="course-list">
				<el-carousel :interval="4000" height="600px" indicator-position="none" arrow="never" class="course-list-box" :autoplay="false">
					<el-carousel-item v-for="item in 5" :key="item" class="course-item">
						<div class="course-box">
							<div class="course-img"></div>
						</div>
					</el-carousel-item>
				</el-carousel>
			</div> -->
			<div class="count-box" v-if="false">
				<div class="box-title">
					<div class="title-zh">
						<span class="num">05</span>
						<span class="text">启牛动态</span>
					</div>
					<div class="title-english">QiNiu news</div>
				</div>
				<div class="news_container">
					<div class="new_item">
						<div class="new_title">2022年服贸会在京开幕 启牛学堂亮相服贸会“云上展厅”</div>
						<div class="new_text new_text_s">
							8月31日，2022年中国国际服务贸易交易会在京拉开帷幕。本届服贸会以“服务合作促发展  绿色创新迎未来”为主题，延续“综合+专题”“线上+线下”的办会模式，为国内外企业提供了展示、交流、商务洽谈的良好契机。京东云、蚂蚁集团、中国银行、量子之歌等众多企业一同亮相“云上展厅”。
							<a class="new_link">查看全文></a>
						</div>
					</div>
					<div class="new_item">
						<div class="new_title">2022年服贸会在京开幕 启牛学堂亮相服贸会“云上展厅”</div>
						<div class="new_text new_text_s">
							8月31日，2022年中国国际服务贸易交易会在京拉开帷幕。本届服贸会以“服务合作促发展  绿色创新迎未来”为主题，延续“综合+专题”“线上+线下”的办会模式，为国内外企业提供了展示、交流、商务洽谈的良好契机。京东云、蚂蚁集团、中国银行、量子之歌等众多企业一同亮相“云上展厅”。
							<a class="new_link">查看全文></a>
						</div>
					</div>
				</div>
			</div>
			<div class="component-footer">
				<div class="component-footer-common">
					<div>用户隐私政策</div>
					<div class="line"></div>
					<div>用户服务协议</div>
					<div class="line"></div>
					<div>吉ICP备2022005057号-2</div>
					<div class="line"></div>
					<div>服务热线:400-8767985</div>
				</div>
				<!-- <div>Copyright @ 2009-2022 菲尔莱（北京）科技有限公司 All Rights Reserved 京ICP备18056030号-24</div>
				<div>公司地址：北京市朝阳区来广营容和路1号 叶青大厦北园6层</div> -->
			</div>
		</div>
  </div>
</template>

<script>
import router from '../router'
import Swiper from "swiper"
export default {
  name: 'Home',
  components: {
    
  },
	beforeCreate(){
		const _this = this
    var sUserAgent = navigator.userAgent.toLowerCase();
    var bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
    var bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
    var bIsMidp = sUserAgent.match(/midp/i) == "midp";
    var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
    var bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
    var bIsAndroid = sUserAgent.match(/android/i) == "android";
    var bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
    var bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
    if (bIsIpad || bIsIphoneOs || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE || bIsWM) {
      _this.$router.push("/mobile/home")
    }
	},
  data(){
	  return {
		  routes:router.options.routes,
			scrollTop:0,
	  }
  },
  mounted() {
	const _this = this;
		window.onscroll = function(){
			const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
			// console.log("scrollTop=========",scrollTop)
			_this.scrollTop = scrollTop
		}
	}
}
</script>

<style lang="less" scoped="scoped">
@import url("../../node_modules/swiper/swiper.less");
.nav{
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	z-index: 99;
	height: 70px;
	transition: all .3s;
	.inner{
		width: 1200px;
		height: 100%;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.logo-box{
			display: flex;
			align-items: center;
		}
		.logo{
			display: block;
			width: 40px;
			height: 40px;
			cursor: pointer;
			margin-right: 10px;
		}
		.list-box{
			display: flex;
			.item{
				font-size: 16px;
				line-height: 32px;
				margin-left: 37px;
				cursor: pointer;
				padding: 0 23px;
			}
			.active{
				font-weight: 700;
				border-radius: 16px;
				color: #ff1001;
				background-color: #ffefeb;
			}
			.nav-item1{
				color: #3b3a43;
			}
			.nav-item2{
				color: #ffffff;
			}
		}
	}
}
.nav-active{
	background: #fff;
	box-shadow: 0 3px 3px #eee;
}
/deep/ .header-banner .el-carousel__indicators--horizontal{
	bottom: 78px !important;
	left: 100px !important;
	transform: none !important;
}
/deep/ .header-banner .el-carousel__button{
	width: 48px !important;
	height: 6px !important;
	border-radius: 4px !important;
	background: rgba(59,58,67,.2) !important;
	opacity: 1 !important;
}
/deep/ .is-active .el-carousel__button{
	background: #ffffff !important;
}
.container{
	position: relative;
	z-index: 2;
	.count-box{
		position: relative;
		width: 1180px;
		margin: 0 auto;
		padding-top: 140px;
		background-color: #fff;
	}
	.count-box1{
		border-radius: 40px 40px 0 0;
	}
	.box1-list{
		display: flex;
		justify-content: space-between;
		margin-top: 133px;
		padding: 0 37px;
		.list-item{
			cursor: pointer;
			transition: all .2s ease-in-out;
			position: relative;
			width: 260px;
			height: 400px;
			background-color: #fff;
			box-shadow: 0 2px 28px 0 rgba(133,138,150,.12);
			border-radius: 28px;
			display: flex;
			flex-direction: column;
			align-items: center;
			.item-img{
				width: 160px;
				margin-bottom: 24px;
				margin-top: -80px;
			}
			.item-title{
				font-weight: 800;
				font-size: 24px;
				color: #3b3a43;
				letter-spacing: 0;
				line-height: 1;
				margin-bottom: 23px;
			}
			.item-des{
				font-size: 13px;
				color: #3b3a43;
				letter-spacing: 0;
				text-align: justify;
				line-height: 24px;
				margin: 0 36px;
			}
			.item-btn{
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				bottom: 37px;
				display: flex;
				align-items: center;
				justify-content: center;
				border: 1px solid #ff1001;
				border-radius: 20px;
				width: 150px;
				height: 37px;
				line-height: 37x;
				text-align: center;
				color: #ff1001;
				font-size: 16px;
				img{
					margin-left: 10px;
					width: 15px;
					height: 12px;
				}
			}
		}
	}
	.count-box2{
		padding-top: 140px;
		background-color: #fff;
	}
	.advantage-list{
		width: 1180px;
		margin: 0 auto;
		padding: 140px 80px 0;
		box-sizing: border-box;
		.advantage-item{
			height: 205px;
			display: flex;
			justify-content: space-between;
			margin-bottom: 40px;
			.item-left{
				width: 378px;
				height: 205px;
				display: inline-block;
				img{
					width: 100%;
					height: 100%;
				}
			}
			.item-right{
				width: 355px;
				height: 205px;
				display: inline-block;
				box-sizing: border-box;
				padding: 60px 0 30px;
				.item-title{
					font-family: PingFangSC-Semibold;
					font-size: 26px;
					color: #3b3a43;
					letter-spacing: 0;
					line-height: 28.2px;
					margin-bottom: 30px;
					font-weight: 700;
					display: flex;
					align-items: center;
					img{
						margin-right: 10px;
					}
				}
				.item-des{
					font-family: PingFangSC-Regular;
					font-size: 14px;
					color: #555;
					letter-spacing: 0;
					text-align: justify;
					line-height: 24px;
				}
			}
		}
	}
}
.container1{
	margin-top: -44px;
}
.box-title{
	width: 100%;
  text-align: center;
  line-height: 1;
  font-weight: 600;
	.title-zh{
		font-size: 34px;
		color: #3b3a43;
		.num{
			position: relative;
			font-size: 70px;
			top: 4px;
			margin-right: 20px;
		}
		.text{
			position: relative;
			top: -4px;
		}
	}
	.title-english{
		color: #f0f0f0;
		font-size: 32px;
		margin-top: 6px;
	}
}
.adviser-container{
	width: 100%;
	.adviser-content{
		width: 100%;
		height: 350px;
		background: linear-gradient(180deg,#fff0ed,#ffd9d0);
		border-radius: 20px;
		position: relative;
		margin-top: 40px;
		.adviser-teacher{
			width: auto;
			height: 377px;
			position: absolute;
			bottom: 0;
			right: 0;
		}
		.adviser-label{
			width: 342px;
			height: 82px;
			position: absolute;
			left: -15px;
			top: 15px;
		}
		.adviser-info{
			width: 677px;
			height: auto;
			margin: 0 0 20px 100px;
			padding-top: 100px;
			p{
				font-family: PingFangSC-Medium;
				font-size: 14px;
				color: #ff4935;
				letter-spacing: 0;
				text-align: justify;
				line-height: 26px;
				position: relative;
			}
			p::before{
				content: "";
				width: 8px;
				height: 8px;
				border-radius: 50%;
				background: #ff4935;
				position: absolute;
				top: 10px;
				left: -25px;
			}
		}
		.adviser-feature,.adviser-work{
			font-family: PingFangSC-Medium;
			font-size: 14px;
			color: #974c4c;
			letter-spacing: 0;
			text-align: justify;
			margin: 20px 0 20px 75px;
		}
		.adviser-work{
			width: 607px;
			height: 70px;
			background: url(https://qnunion.feierlaiedu.com/kcschool/202011130309_adviser-book.png);
				background-size: auto;
			background-size: 100% 100%;
			box-sizing: border-box;
			line-height: 26px;
			padding: 8px 10px 8px 40px;

			span{
				color: #ff4935;
			}
		}
	}
}
.adviser-title{
	font-family: PingFangSC-Regular;
	font-size: 34px;
	color: #555;
	letter-spacing: 0;
	line-height: 34px;
	padding: 100px 0 40px;
	text-align: center;
}
/deep/ .teacher-container{
	width: 1110px;
	margin: 20px auto !important;
	margin-bottom: 80px !important;
	.el-carousel__arrow{
		width: 70px;
		height: 70px;
		font-size: 40px;
		background-image: linear-gradient(131deg,#ffa68a 2%,#ff4034);
		// background: #ffffff;
		// color: #3b3a43;
	}
	.el-carousel__item{
		display: flex;
		align-items: center;
	}
	.el-carousel__button{
		width: 48px !important;
		height: 6px !important;
		border-radius: 4px !important;
		background: #e5e5e5 !important;
		opacity: 1 !important;
	}
	.is-active .el-carousel__button{
		background: #3b3a43 !important;
	}
	.carousel-box{
		display: flex;
		position: relative;
		margin: auto;
	}
	.carousel-item{
		width: 858px;
		height: 388px;
		margin: auto;
	}
	.carousel-item-info{
		width: 55%;
		height: 388px;
		position: absolute;
		top: 0px;
		right: 0;
		.teacher-name{
			position: relative;
			display: inline-block;
			margin-top: 36px;
			font-size: 35px;
			font-weight: 700;
			color: #3b3a43;
		}
		.teacher-name::before{
			content: "";
			position: absolute;
			display: block;
			left: 0;
			right: 0;
			bottom: -10px;
			height: 8px;
			background-image: linear-gradient(131deg,#ffa68a 2%,#ff4034);
			z-index: 2;
		}
		.teacher-desc{
			width: 100%;
			white-space: nowrap;
			margin-top: 47px;
			margin-left: 20px;
			list-style-type: disc;
			color: #3b3a43;
			font-size: 14px;
			line-height: 35px;
			white-space: pre-line;
			li{
				list-style: none;
				position: relative;
				cursor: default;
				line-height: 1.6;
			}
			li::before{
				content: "";
				width: 8px;
				height: 8px;
				border-radius: 50%;
				background-color: #ff1001;
				position: absolute;
				left: -18px;
				top: 7px;
			}
		}
	}
}
.course-list{
	width: 1180px;
	margin: 0 auto;
	margin-top: 120px;
	/deep/ .el-carousel__container{
		display: flex;
		transition-property: transform;
		box-sizing: content-box;
	}
	.course-item{
		width: 393.33px;
		padding-top: 120px;
		box-sizing: border-box;
		// display: none;
		// background: #ffffff;
	}
	.is-in-stage{
		// display: block;
	}
	.course-box{
		width: 333px;
		height: 420px;
		background: rgb(255, 237, 233);
		border-radius: 20px;
		margin: 0 auto;
		position: relative;
		.course-img{
			width: 146px;
			height: 146px;
			border-radius: 50%;
			background: #e5e5e5;
			position: absolute;
			left: 50%;
			margin-left: -73px;
			top: -73px;
		}
	}
}
.news_container{
	margin-top: 80px;
	margin-bottom: 240px;
	.new_item{
		padding: 35px 30px 35px 50px;
		margin-bottom: 28px;
		.new_title{
			font-family: PingFangSC-Semibold;
			font-size: 22px;
			color: #3b3a43;
			letter-spacing: 0;
			text-align: justify;
			line-height: 26px;
			margin-bottom: 10px;
			font-weight: 800;
			position: relative;
		}
		.new_title::before{
			content: "";
			width: 10px;
			height: 10px;
			border-radius: 50%;
			display: block;
			background: #ff4935;
			position: absolute;
			top: 8px;
			left: -20px;
		}
		.new_text{
			font-family: PingFangSC-Regular;
			font-size: 15px;
			color: #3b3a43;
			letter-spacing: 0;
			text-align: justify;
			line-height: 26.32px;
		}
		.new_text_s{
			position: relative;
			.new_link{
				float: right;
				font-family: PingFangSC-Regular;
				font-size: 13px;
				color: #6d6c73;
				letter-spacing: 0;
				text-decoration: none;
				cursor: pointer;
			}
		}
	}
	.new_item:nth-child(2n+1){
		background-image: linear-gradient(180deg,#fffbfa,#fff1ee);
		border-radius: 28px;
	}
	.new_item:nth-child(2n){
		background: #f7f8fa;
		border-radius: 28px;
	}
}
.component-footer{
	width: 100%;
	height: 80px;
	background: #4f5669;
	font-size: 12px;
	color: #fffdfd;
	text-align: center;
	flex-direction: column;
	justify-content: center;
	line-height: 30px;
	display: flex;
	align-items: center;
	.component-footer-common{
		display: flex;
		align-items: center;
		// justify-content: center;
		.line{
			width: 1px;
			height: 12px;
			margin: 0 12px;
			background: #fffdfd;
		}
	}
}
</style>